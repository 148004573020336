import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterLink } from '@angular/router';

import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

// eslint-disable-next-line max-len
import { LoginRegisterModalComponent } from '../../../../app/client/budget-details/login-register-modal/login-register-modal.component';
import { UserRole } from '../../../../definitions/user-role.enum';
import { LoadingStore } from '../../../../stores/loading.store';
import { AuthService } from '../../auth/auth.service';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, RouterLink, MatButtonModule, TranslateModule]
})
export class LoginFormComponent implements OnInit, OnDestroy {
  /**
   * Login form
   */
  public loginForm: FormGroup;

  /**
   * Indicator that the component is destroyed
   * @private
   */
  private destroyed = new Subject();

  /**
   * The user role
   */
  role: UserRole;

  /**
   * Value to show an icon or text for password field
   */
  showPassword = false;

  /**
   * Modal data if the form is called from a modal
   */
  @Input() modal: MatDialogRef<LoginRegisterModalComponent> | undefined;

  /**
   * Event emitter that will trigger an event when the login  is correct.
   */
  @Output() logged: EventEmitter<string | null> = new EventEmitter();

  translateService: TranslateService | undefined;

  /**
   * Component constructor
   */
  constructor(
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private inj: Injector,
    public loading: LoadingStore
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
    // The role is client
    this.role = UserRole.CLIENT;
  }
  /**
   * Creates the login form fields and their validations
   */
  ngOnInit(): void {
    this.translateService = this.inj.get(TranslateService);
    // If already logged go to home
    if (this.authService.loggedIn()) {
      this.logged.emit(null);
    }
  }

  /**
   * Method called on the event 'onSubmit', if an access_token is received then navigates to home otherwise shows
   * the error
   */
  public login(): void {
    this.translateService = this.inj.get(TranslateService);
    if (this.loginForm.valid) {
      this.loading.start();
      this.loginForm.disable();
      this.authService
        .login(this.loginForm.value, this.role)
        .pipe(skip(1), takeUntil(this.destroyed))
        .subscribe((loginOk: any) => {
          if (!this.modal) {
            this.authService.successLoginInfoAndRedirect(loginOk, this.role);
          } else {
            this.authService.successLoginInfoAndRedirect(loginOk, this.role, true);
            this.modal.close(loginOk);
          }
        })
        .add((): void => {
          this.loading.stop();
        });
    }

    this.loginForm.enable();
  }

  /**
   * Method to show or hide the password
   * @param event
   */
  togglePasswordVisibility(event: MouseEvent): void {
    this.showPassword = !this.showPassword;
    event.stopImmediatePropagation();
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
